import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: '首页',
        component: HomeView,
        children: [
            {
                path: '/',
                component: () => import('@/views/CheckView.vue')
            },
            {
                path: '/edit',
                component: () => import('@/views/EditView.vue')
            },
        ]
    },
    {
        path: '/brand',
        name: '品牌列表',
        component: () => import("@/views/BrandView.vue"),
        children: [
            {
                name: "品牌管理",
                path: "list",
                component: () => import("@/views/brand/ListView.vue"),
            },
            {
                name: "添加品牌",
                path: "add",
                component: () => import("@/views/brand/AddView.vue"),
            },
            {
                name: "编辑品牌",
                path: "edit",
                component: () => import("@/views/brand/EditView.vue"),
            }
        ]

    },
    {
        path: '/card',
        name: '证书管理',
        component:() => import("@/views/CardView.vue"),
        children: [
            {
                name:"证书列表",
                path: 'list',
                component:()=>import("@/views/card/ListView.vue")
            },
            {
                name:"添加证书",
                path: 'add',
                component:()=>import("@/views/card/AddView.vue")
            },
            {
                name:"编辑证书",
                path: 'edit',
                component:()=>import("@/views/card/EditView.vue")
            }
        ]
    },
    {
        path: '/aptitude',
        name: '广审管理',
        component: () => import("@/views/Aptitude.vue"),
        children: [
            {
                name:"广审列表",
                path: 'list',
                component:()=>import("@/views/aptitude/ListView.vue")
            },
            {
                name:"添加广审",
                path: 'add',
                component:()=>import("@/views/aptitude/AddView.vue")
            },
            {
                name:"编辑广审",
                path: 'edit',
                component:()=>import("@/views/aptitude/EditView.vue")
            }
        ]
    },
    {
        name: "管理",
        path: "/tube",
        component: () => import("@/views/TubeView.vue"),
    },
    {
        name: "用户管理",
        path: "/user",
        component:()=>import("@/views/UserView.vue"),
        children: [
            {
                path: "list",
                component: () => import("@/views/user/ListView.vue"),
            },
            {
                path: "edit",
                component: () => import("@/views/user/InfoView.vue"),
            }
        ]
    },
    {
        name:"基础设置",
        path: "/basics",
        component: ()=>import("@/views/BasicsView.vue")
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
