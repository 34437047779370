import axios from 'axios';
import Router from "@/router";
import {showToast,showLoadingToast,allowMultipleToast} from "vant";

allowMultipleToast();
class Request {

    constructor(config) {

        this.toast=[];
        this.instance = axios.create(config)
        this.instance.interceptors.request.use(
            config => {
                config.headers["Content-Type"] = "application/x-www-form-urlencoded";
                const token = localStorage.getItem('token')
                if(token) config.headers['token'] = `${token}`

                this.toast.push(showLoadingToast({
                    message: '加载中...',
                    forbidClick: true,
                    duration:0,
                    loadingType: 'spinner',
                }));
                return config
            }
        )

        // 响应拦截器
        this.instance.interceptors.response.use(
            result => {

                for(let k in this.toast){
                    this.toast[k].close();
                }

                if(result.data.code && result.data.code==301){
                    localStorage.clear();
                    Router.push("/");
                    showToast({
                        message: result.data.msg,
                        wordBreak: 'break-word',
                    });
                    return Promise.reject(result.data)
                }

                if(result.data.code && result.data.code==401){
                    Router.back();
                    showToast({
                        message: result.data.msg,
                        wordBreak: 'break-word',
                    });
                    return Promise.reject(result.data)
                }

                if(result.data.code!=200){
                    showToast({
                        message: result.data.msg,
                        wordBreak: 'break-word',
                    });
                    return Promise.reject(result.data);
                }

                return Promise.resolve(result.data)
            },
            error => {
                if (error.response) {

                } else {

                }
                for(let k in this.toast){
                    this.toast[k].close();
                }

                return Promise.reject(error)
            }
        )
    }

    get(url, params) {
        return this.instance.get(url, {params})
    }

    post(url, data) {
        return this.instance.post(url, data)
    }

    patch(url, data) {
        return this.instance.patch(url, data)
    }

    delete(url) {
        return this.instance.delete(url)
    }
}


const config = {
    // baseURL: '/api',
    timeout: 10 * 1000
}
export default new Request(config)
