import axios from "@/utils/request";
import userStore from "@/utils/userStore";

const api={

    Login(data){
        return axios.post("/api/user/login",data)
    },
    info(data){
        return axios.get("/api/user/info",data);
    },
    lists(data){
        return axios.get("/api/user/lists",data);
    },
    edit(data){
        return axios.post("/api/user/edit",data)
    },
    hint(data){
        return axios.get("/api/user/hint",data);
    },

    reg(data){
        return axios.post("/api/user/reg",data)
    }


}


export default api;
