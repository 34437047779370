
const userStore={

    get(name=''){
        let userinfo=localStorage.getItem("user");

        if(userinfo!="undefined"){
            const user=JSON.parse(userinfo)
            if(user){
                if(name){
                    return user[name];
                }
                return user;
            }
        }
    },

    set(data){
        localStorage.setItem("user",JSON.stringify(data))
    },
    out(){
        localStorage.clear()
    }

}

export default userStore;