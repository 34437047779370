import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4663262c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "margin": "16px"
  }
};
import { ref } from "vue";
import userApi from "@/api/user";
import { showToast } from 'vant';
export default {
  __name: 'Reg',
  emits: ['showTost'],
  setup(__props, {
    emit
  }) {
    const name = ref('');
    const phone = ref('');
    const user = ref('');
    const pwd = ref('');
    const cpwd = ref('');
    const onSubmit = values => {
      let prg = /^1[3-9]\d{9}$/;
      if (!prg.test(phone.value)) {
        showToast("请输入正确的手机号!");
        return;
      }
      if (pwd.value != cpwd.value) {
        showToast("密码不一致!");
        return;
      }
      userApi.reg(values).then(res => {
        if (res.code == 200) {
          localStorage.setItem("token", res.data.token);
          showToast({
            message: res.msg,
            wordBreak: 'break-word'
          });
          emit("showTost", 1);
        }
      });
    };
    const onLogin = () => {
      emit("showTost", 1);
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createBlock(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: name.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
            name: "name",
            label: "昵称",
            placeholder: "昵称",
            rules: [{
              required: true,
              message: '请填写昵称'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: phone.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => phone.value = $event),
            name: "phone",
            label: "手机号",
            placeholder: "手机号",
            rules: [{
              required: true,
              message: '请填写手机号'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: user.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => user.value = $event),
            name: "user",
            label: "用户名",
            placeholder: "用户名",
            rules: [{
              required: true,
              message: '请填写用户名'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: pwd.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => pwd.value = $event),
            type: "password",
            name: "pwd",
            label: "密码",
            placeholder: "密码",
            rules: [{
              required: true,
              message: '请填写密码'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: cpwd.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => cpwd.value = $event),
            type: "password",
            name: "cpwd",
            label: "确认密码",
            placeholder: "确认密码",
            rules: [{
              required: true,
              message: '请再次输入密码'
            }]
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "login",
          onClick: onLogin
        }, "已有账号，现在登陆"), _createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 注册 ")]),
          _: 1
        })])]),
        _: 1
      });
    };
  }
};