import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63a06e2a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "content"
};
import { ref, onMounted } from 'vue';
import Login from "@/components/Login.vue";
import Reg from "@/components/Reg.vue";
import userApi from "@/api/user";
import userStore from "@/utils/userStore";
import { useRoute, useRouter } from 'vue-router';
import { showConfirmDialog, showToast } from "vant";
export default {
  __name: 'HomeView',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const user = ref({});
    let showLogin = ref(false);
    let showReg = ref(false);
    const out = () => {
      showConfirmDialog({
        title: '提示',
        message: '确定退出当前账号？'
      }).then(() => {
        userStore.out();
        user.value = false;
        showToast("已安全退出");
        location.reload();
      }).catch(() => {
        // on cancel
      });
    };
    onMounted(() => {
      getuserinfo();
      user.value = userStore.get();
    });
    const showTost = e => {
      if (e == 0) {
        showLogin.value = false;
        showReg.value = false;
        setTimeout(function () {
          location.reload();
        }, 200);
      } else if (e == 1) {
        showLogin.value = true;
        showReg.value = false;
      } else {
        showLogin.value = false;
        showReg.value = true;
      }
    };
    const toBrand = () => {
      getuserinfo();
      if (user.value.is_auth || user.value.is_admin) {
        router.push("/brand/list");
      }
    };
    const toTube = () => {
      router.push("/tube");
    };
    const getuserinfo = () => {
      userApi.info().then(res => {
        if (res.data.info) {
          userStore.set(res.data.info);
          user.value = userStore.get();
        }
      });
    };
    const onCancel = () => {
      showLogin.value = false;
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_router_view = _resolveComponent("router-view");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [user.value ? (_openBlock(), _createBlock(_component_van_nav_bar, {
        key: 0,
        title: "查询",
        "right-text": "管理",
        onClickRight: toTube,
        "left-text": "退出登录",
        onClickLeft: out
      })) : (_openBlock(), _createBlock(_component_van_nav_bar, {
        key: 1,
        title: "查询",
        "right-text": "登录",
        onClickRight: _cache[0] || (_cache[0] = $event => showTost(1))
      })), _createVNode(_component_router_view)]), _createVNode(_component_van_action_sheet, {
        show: _unref(showLogin),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(showLogin) ? showLogin.value = $event : showLogin = $event),
        onCancel: onCancel,
        title: "登录"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(Login, {
          onShowTost: showTost
        })])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_action_sheet, {
        show: _unref(showReg),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(showReg) ? showReg.value = $event : showReg = $event),
        onCancel: onCancel,
        title: "注册"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(Reg, {
          onShowTost: showTost
        })])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};